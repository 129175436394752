import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import FaqCard from './Card';

function HPAD({pageInfo}) {
    // useEffect(() => {
    //     pageInfo('HPAD');
    // }, [])
    return ( 
        <>
            <Row className="justify-content-center text-center mb-md-3">
                <Col lg={12}>
                    <div className="section-title">
                        <h2>HPAD</h2>
                    </div>
                </Col>
            </Row>

            <Row>
                {DATA.map((item, index) => (
                    <Col sm={6} key={index} className="mb-md-4 mb-sm-3 mb-2">
                        <FaqCard head={item.title} body={item.content} />
                    </Col>
                ))}
            </Row>
        </>
     );
}

export default HPAD;

const DATA = [
    {
        title: 'What is HPAD?',
        content: 'HPAD is an automated Launchpad innovates by providing an affordable alternative for projects to raise startup capital in the form of cryptocurrency or stable coins'
    },
    {
        title: 'What is the benefits to launch with HPAD?',
        content: 'Projects can raise awareness through our services and get in contact with key experts within the DeFi space'
    },
    {
        title: 'What are the fees to create a presale on HPAD?',
        content: 'Our fee structure is 1 BNB and 1% of native token raised, to undercut the majority of the market, not just to improve sales but also to provide these projects with bigger startup capital'
    },
    {
        title: 'Isn’t it too cheap to launchpad HPAD? Why 1 + 1%?',
        content: 'We want HPAD to be a helpful service that provides more startup capital a project has, the better chances it has to succeed in the long term'
    },
    {
        title: 'Where can I find a live Presale?',
        content: 'A live, ended and progressing presale can be found on the <a href="/hpad/">Presale List</a>'
    },
    {
        title: 'What blockchains does the launchpad support?',
        content: 'HedgePay launchpad supports 170+ blockchains (EVM). Any of the chain is not displayed on the launchpad UI, <a href="/contact">contact us</a> to create the presale manually'
    },
    {
        title: 'Where can I create a presale?',
        content: 'To create a presale please visit <a href="/hpad/create">create presale</a>'
    },
    {
        title: 'Where can I book a liquidity locker?',
        content: 'For now the liquidity locker can be done manually <a href="/contact">contact us</a> A new liquidity lock dashboard will be live soon'
    }
]